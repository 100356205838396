<template>
  <div>
    <div class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>

    <v-form ref="form" class="row">
      <input-text
        v-model="form.codigo"
        class="col-12 col-md-4"
        :label="$t('modulos.procedimento.formulario.codigo')"
        obrigatorio
        :max="50"
      />
      <input-text
        v-model="form.titulo"
        class="col-12 col-md-8"
        :label="$t('modulos.procedimento.formulario.titulo')"
        :max="100"
      />
      <input-textarea
        v-model="form.descricao"
        class="col-12"
        :label="$t('modulos.procedimento.formulario.descricao')"
        :max="1000"
      />
    </v-form>

    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao outlined color="secondary" class="mr-2" @click="cancelar">
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao @click="confirmacaoSalvar">
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import ProcedimentoService from '@common/services/cadastros/ProcedimentoService';
import { ProcedimentoModel } from '@common/models/cadastros/ProcedimentoModel';
import helpers from '@common/utils/helpers';
export default {
  props: ['id'],
  data() {
    return {
      valido: false,
      form: new ProcedimentoModel({}),
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.procedimento.titulos.editar');
      return this.$t('modulos.procedimento.titulos.novo');
    },
  },
  mounted() {
    if (this.id) {
      helpers.redirecionarSemPermissao(this, 'Procedimento', 'Editar');
      this.buscar(this.id);
    }
    helpers.redirecionarSemPermissao(this, 'Procedimento', 'Inserir');
    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },
  methods: {
    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ProcedimentoService.buscar(this.id)
        .then((res) => {
          this.form = new ProcedimentoModel(res.data);
          this.valido = true;
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.procedimento.erros.id_invalido'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar: function () {
      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    salvar: function () {
      if (this.$refs.form) this.valido = this.$refs.form.validate();
      if (!this.valido) return;
      this.$store.dispatch('Layout/iniciarCarregamento');
      ProcedimentoService.salvar(this.form)
        .then(() => {
          this.toastSucesso(this.$t(`modulos.procedimento.cadastro_sucesso`));
          this.$router.push({ name: 'procedimentos' });
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'procedimentos' });
      });
    },
  },
};
</script>
